class Page {
	// COMMON

	static readonly "" = new Page("", () => "");
	static readonly "*" = new Page("*", () => "*");
	static readonly "/" = new Page("/", () => "/");
	static readonly "/not_found" = new Page("/not_found", (id) => "/not_found");
	static readonly "/succes_send" = new Page("/succes_send", (id) => "/succes_send");

	private constructor(
		public readonly path: string,
		public readonly makeLink: (...args: string[]) => string,
	) { }

	public toString(): string {
		return this.path;
	}
}

export default Page;
