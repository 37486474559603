import React, { FC } from 'react'
import { Grid } from "@mui/material";
import "@mozaic-ds/react/lib/index.css";
import "../bundle.scss"
import { Button, Text } from '@mozaic-ds/react';
import { useNavigate } from 'react-router-dom';

const SuccesSend: FC = () => {
    const navigate = useNavigate();

    const handleBackToForm = () => {
        navigate('/');
    };

    return (
        <>
            <Grid item container direction="column" alignItems="center" marginBottom={0}>
                <div style={{ marginTop: "40px" }}>
                    <Text weight="regular" size="xxl">
                        Grazie per averci contattato!
                    </Text>
                </div>
                <Text weight="light" size="l">
                    Abbiamo preso in carico la sua richiesta e provvederemo a darle riscontro entro 2 giorni lavorativi.
                </Text>
                <Grid item container direction="column" alignItems="center">
                    <Button style={{ margin: '30px', marginBottom: '0px' }} type="button" theme="neutral" onClick={handleBackToForm}>
                        FORM
                    </Button>
                </Grid>
                <Grid>
                    <img
                        src="https://static.wixstatic.com/media/2ace76_43a0bc384b9e4317b4105a47d689ea0e~mv2.png/v1/fill/w_515,h_460,fp_0.48_0.55,q_85,usm_0.66_1.00_0.01,enc_auto/image%20(13).png"
                        alt="Servizi Golilla"
                        style={{
                            objectFit: 'cover',
                            objectPosition: '50% 50%',
                    }}
                        loading="lazy"
                    />
                </Grid>
            </Grid>
        </>
    )
};

export default SuccesSend;