export async function fromBlobToBase64(file: Blob, withHeader?: boolean) {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			let base64data: string = reader.result ? reader.result.toString() : "";
			if (!withHeader) {
				base64data = base64data.split(",")[1];
			}
			resolve(base64data);
		};

		// eslint-disable-next-line
		reader.onerror = (e: any) => {
			reject(e);
		};
	});
}