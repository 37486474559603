import React, { FC, useRef, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
	Select,
	TextInput,
	TextArea,
	Button,
	Tooltip,
	Text,
	FileUploader,
	IFileUploaderFile,
	Loader
} from "@mozaic-ds/react";
import { Form, Field } from "react-final-form";
import {
	RegioneOptions,
	ServizioOptions,
	MotivoOptionsConsegna,
	MotivoOptionsReso,
	MotivoOptionsPosa,
} from "../utils/DropdownOptions.tsx";
import {
	composeValidators,
	isEmail,
	isTelephoneNumber,
	required
} from "../utils/FieldValidators.tsx"
import { Grid } from "@mui/material";
import "@mozaic-ds/react/lib/index.css";
import "../bundle.scss"
import { ContactUsAPI } from "../api/contactUs/ContactUsAPI.tsx";
import { ContactRequestDTO } from "../api/contactUs/ContactRequestDTO.tsx";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { fromBlobToBase64 } from "../utils/utils.tsx";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { FileDTO } from "../api/contactUs/FileDTO.tsx";

const ContactUsPage: FC = () => {

	const [searchParams] = useSearchParams();
	const [uploadedFilesMetaData, setUploadedFilesMetaData] = useState<IFileUploaderFile[]>([]);
	const [uploadedFiles, setUploadedFiles] = useState<FileDTO[]>([]);
	const [showReCAPTCHAError, setShowReCAPTCHAError] = useState<boolean>(false)
	const [showInvalidFileMessage, setShowInvalidFileMessage] = useState<boolean>(false);
	const [showLoader, setShowLoader] = useState<boolean>(false);
	const [showSubmitMessage, setShowSubmitMessage] = useState<string>("");
	const [serviceValue, setServiceValue] = useState(searchParams.get('serviceType') || '');
	const recaptcha = useRef<typeof ReCAPTCHA>(null);

	const uploadFile = async (fileEvent) => {
		const file = fileEvent.target.files[0];

		if (file &&
			(file.name.endsWith('.pdf') || file.name.endsWith('.png') || file.name.endsWith('.jpg') || file.name.endsWith('.jpeg') || file.name.endsWith('.heic'))) {
			const encodedFile = await fromBlobToBase64(file, true);
			const newFile = { name: file.name, fileBase64: encodedFile };
			console.log(encodedFile);
			setUploadedFiles([...uploadedFiles, newFile]);
			setUploadedFilesMetaData([...uploadedFilesMetaData, { name: file.name }]);
		} else {
			setShowInvalidFileMessage(true);
		}
	}

	const removeFile = (fileName) => {
		const newUploadedFiles: FileDTO[] = []
		const newUploadedFilesMetaData: IFileUploaderFile[] = []
		uploadedFilesMetaData.forEach(file => {
			if (file.name !== fileName) {
				newUploadedFiles.push(uploadedFiles[uploadedFilesMetaData.findIndex(f => f.name === file.name)])
				newUploadedFilesMetaData.push(file)
			}
		});
		setUploadedFiles(newUploadedFiles)
		setUploadedFilesMetaData(newUploadedFilesMetaData)
	}

	const navigate = useNavigate();
	const submitForm = async (payload: ContactRequestDTO) => {
		if (recaptcha.current?.getValue()) {
			setShowReCAPTCHAError(false)
			setShowLoader(true)
			setShowSubmitMessage("")
			await ContactUsAPI.contactUs(payload, uploadedFiles)
				.then(() => {
					setShowLoader(false)
					setShowSubmitMessage("success")
					setTimeout(() => {
						navigate('/succes_send');
					}, 0);
				}).catch(() => {
					setShowLoader(false)
					setShowSubmitMessage("error")
				})
		} else {
			setShowReCAPTCHAError(true)
			setShowSubmitMessage("")
		}
	}

	return (
		<>
			<Form
				onSubmit={submitForm}
				initialValues={{
					shippingNumber: searchParams.get('shippingNumber'),
					serviceType: searchParams.get('serviceType'),
					region: searchParams.get('region'),
					email: searchParams.get('email'),
					puntoVendita: searchParams.get('puntoVendita')
				}}
				render={({ handleSubmit, form }) => (
					<form onSubmit={handleSubmit} className={`formContainer ${showLoader ? 'loading' : ''}`}>
						<Grid container direction="column" sm={4} xs={12} spacing={4} marginTop={6}>

							<Grid item container direction="column" spacing={2} marginLeft={0}>
								<Grid>
									<Text>
										<p className="poppins-light" style={{ fontSize: "18px" }}>
											Compilando il form, entrerà in contatto con il <span className="poppins-semibold" style={{ fontSize: "18px" }}>customer service</span> che le risponderà entro <span className="poppins-semibold" style={{ fontSize: "18px" }}>2 giorni lavorativi</span> dalla ricezione della mail.<br></br>
											I campi segnati con <span className="poppins-semibold" style={{ fontSize: "18px" }}>* sono obbligatori.</span>
										</p>
									</Text>
								</Grid>
							</Grid>

							<Grid item container direction="column" spacing={2}>
								<Field
									name="serviceType"
									validate={composeValidators(required)}
									render={({ input, meta }) => (
										<Grid item>
											<Text>Servizio *</Text>
											<Select
												{...input}
												value={serviceValue}
												onChange={(e) => {
													input.onChange(e);
													setServiceValue(e.target.value)
												}
												}
												options={ServizioOptions}
												className="inputField"
											/>
											{meta.error && meta.touched && <Text weight="regular" theme="danger">{meta.error}</Text>}
										</Grid>
									)}
								/>

								<Field
									name="reason"
									validate={composeValidators(required)}
									render={({ input, meta }) => (
										<Grid item hidden={!serviceValue}>
											{serviceValue && <Text>Motivo contatto *</Text>}
											<Select
												{...input}
												options={serviceValue === "Consegna" ? MotivoOptionsConsegna :
													serviceValue === "Reso" ? MotivoOptionsReso :
														serviceValue === "Posa" ? MotivoOptionsPosa : []}
												className="inputField"
											/>
											{meta.error && meta.touched && <Text weight="regular" theme="danger">{meta.error}</Text>}
										</Grid>
									)}
								/>

							</Grid>

							<Grid item container direction="column" spacing={2}>
								<Grid item container direction='row' spacing={1}>
									<Grid item>
										<Text weight="semi-bold"><h1>Informazioni Utente</h1></Text>
									</Grid>
									<Grid item>
										<Tooltip label={"In questa sezione, inserire nominativo e riferimenti della richiesta di contatto e/o con cui è stato registrato l'ordine di acquisto"} placement="top">
											<HelpOutlineOutlinedIcon />
										</Tooltip>
									</Grid>
								</Grid>

								{searchParams.get('isPV') !== 'false' &&
									<Field
										name="puntoVendita"
										render={({ input, meta }) => (
											<Grid item>
												<Text>Codice punto vendita</Text>
												<TextInput
													{...input}
													className="inputField"
												/>
											</Grid>
										)} />
								}

								<Field
									name="fullName"
									validate={composeValidators(required)}
									render={({ input, meta }) => (
										<Grid item>
											<Text>Nome completo / Ragione sociale *</Text>
											<TextInput
												{...input}
												className="inputField"
											/>
											{meta.error && meta.touched && <Text weight="regular" theme="danger">{meta.error}</Text>}
										</Grid>
									)} />

								<Field
									name="email"
									validate={composeValidators(required, isEmail)}
									render={({ input, meta }) => (
										<Grid item>
											<Text>E-mail *</Text>
											<TextInput
												{...input}
												className="inputField"
											/>
											{meta.error && meta.touched && <Text weight="regular" theme="danger">{meta.error}</Text>}
										</Grid>
									)} />

								<Field
									name="phone"
									validate={composeValidators(required, isTelephoneNumber)}
									render={({ input, meta }) => (
										<Grid item>
											<Text>Telefono *</Text>
											<TextInput
												{...input}
												className="inputField"
											/>
											{meta.error && meta.touched && <Text weight="regular" theme="danger">{meta.error}</Text>}
										</Grid>
									)} />
							</Grid>

							<Grid item container direction="column" spacing={2}>
								<Grid item>
									<Text weight="semi-bold">Informazioni Ordine</Text>
								</Grid>

								<Field
									name="shippingNumber"
									validate={
										['Richiesta info - Altro', 'Richiesta preventivo', 'Assistenza portale', 'Servizi e informazioni', undefined].includes(form.getFieldState("reason")?.value) ? undefined : composeValidators(required)
									}
									render={({ input, meta }) => (
										<Grid item>
											<Grid item container direction='row' spacing={1}>
												<Grid item>
													<Text>Numero ordine {['Richiesta info - Altro', 'Richiesta preventivo', 'Assistenza portale', 'Servizi e informazioni', undefined].includes(form.getFieldState("reason")?.value) ? "" : "*"}</Text>
												</Grid>
												<Grid item>
													<Tooltip label={"Numero di spedizione di 7 cifre o numero ordine di acquisto fornito sulla conferma ordine"} placement="top">
														<HelpOutlineOutlinedIcon />
													</Tooltip>
												</Grid>
											</Grid>
											<TextInput
												{...input}
												className="inputField"
											/>
											{meta.error && meta.touched && <Text weight="regular" theme="danger">{meta.error}</Text>}
										</Grid>
									)} />

								<Field
									name="region"
									validate={composeValidators(required)}
									render={({ input, meta }) => (
										<Grid item>
											<Text>Regione di consegna/ritiro *</Text>
											<Select
												className="inputField"
												{...input}
												options={RegioneOptions}
											/>
											{meta.error && meta.touched && <Text weight="regular" theme="danger">{meta.error}</Text>}
										</Grid>
									)} />
							</Grid>

							<Grid item container direction="column" spacing={2}>
								<Grid item>
									<Text weight="semi-bold">Richiesta</Text>
								</Grid>


								<Field
									name="description"
									validate={composeValidators(required)}
									render={({ input, meta }) => (
										<Grid item>
											<Text>Descrizione *</Text>
											<TextArea
												{...input}
												rows={5}
												className="inputField"
											/>
											{meta.error && meta.touched && <Text weight="regular" theme="danger">{meta.error}</Text>}
										</Grid>
									)} />

								<Field
									name="uploadedFile"
									render={({ input, meta }) => (
										<Grid item>
											<Text>
												Carica un file:
											</Text>
											<FileUploader
												files={uploadedFilesMetaData}
												onChange={async (e) => uploadFile(e)}
												onRemove={(fileName) => removeFile(fileName)}
											></FileUploader>
											<Text>
												File supportati: JPG, PNG, PDF, HEIC<br></br>
												Dimensione massima: 20mb<br></br>
											</Text>
											{showInvalidFileMessage && <Text weight="semi-bold" theme="danger">I formati caricabili sono PDF, JPG, PNG e HEIC</Text>}
										</Grid>
									)}
								/>

							</Grid >


							<Grid item>
								<ReCAPTCHA ref={recaptcha}
									sitekey={window.__RUNTIME_CONFIG__.REACT_APP_SITE_KEY}
									onChange={(e) => setShowReCAPTCHAError(e == null)}
								/>
								{showReCAPTCHAError && <Text weight="semi-bold" theme="danger">Verificare il reCAPTCHA</Text>}
							</Grid>

							<Grid item container direction="row" spacing={2} alignItems={"center"}>
								<Grid item>
									<Button type="submit" theme="neutral">
										INVIA
									</Button>
								</Grid>
								{showLoader &&
									<Grid item>
										<Loader size="l" />
									</Grid>}

								<Grid item>
									{showSubmitMessage === 'error' && <Text weight="semi-bold" theme="danger">Si è verificato un errore, provare più tardi</Text>}
									{showSubmitMessage === 'success' && <Text weight="semi-bold" theme="success">Richiesta inviata con successo</Text>}
								</Grid>
							</Grid>
						</Grid>
					</form>
				)}
			/>
			<Grid>
				<footer style={{ display: "flex", flexDirection: "row", justifyContent: "end", marginTop: "6%" }}>
					<Text weight="regular" size="m">
						Golilla S.R.L. - Via Guglielmo Marconi, 24 - 20089 Rozzano (MI) - P.IVA: 12602750965
					</Text>
				</footer>
			</Grid>
		</>
	);
};

export default ContactUsPage;