import './App.css';
import React from "react";
import { useRoutes } from "react-router-dom";
import { getRootRoutes } from './router.tsx';
import "./bundle.scss"

export const App = (): React.JSX.Element => {

	const routing = useRoutes(getRootRoutes());

	return (
		<html>
			<head>
				<title>Mozaic Test</title>
				<link rel="stylesheet" type="text/css" media="screen" href="bundle.css" />
			</head>
			<body>
				<div className="topBar">
					<img style={{ width: "7.5%", height: "7.5%", marginLeft: "3.5%" }} className='topBarElement' src={require('./assets/images/golilla_vettoriale_HR_rett.jpg')} alt='golilla_vettoriale_HR_rett' />
				</div>
				{routing}
			</body>
		</html>
  );
}

export default App;
