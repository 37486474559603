export const ServizioOptions = [
	{
		label: "",
		value: undefined
	},
	{
		label: "Consegna",
		value: "Consegna"
	},
	{
		label: "Reso",
		value: "Reso"
	},
	{
		label: "Posa",
		value: "Posa"
	}
]

export const MotivoOptionsConsegna = [
	{
		label: "",
		value: undefined
	},
	{
		label: "Altro",
		value: "Altro"
	},
	{
		label: "Annullamento",
		value: "Annullamento"
	},
	{
		label: "Informazioni stato consegna",
		value: "Informazioni stato consegna"
	},
	{
		label: "Reclami",
		value: "Reclami"
	}
]

export const MotivoOptionsReso = [
	{
		label: "",
		value: undefined
	},
	{
		label: "Altro",
		value: "Altro"
	},
	{
		label: "Annullamento",
		value: "Annullamento"
	},
	{
		label: "Informazioni stato reso",
		value: "Informazioni stato reso"
	},
	{
		label: "Reclami",
		value: "Reclami"
	}
]

export const MotivoOptionsPosa = [
	{
		label: "",
		value: undefined
	},
	{
		label: "Altro",
		value: "Altro"
	},
	{
		label: "Annullamento",
		value: "Annullamento"
	},
	{
		label: "Informazioni stato posa",
		value: "Informazioni stato posa"
	},
	{
		label: "Reclami",
		value: "Reclami"
	}
]

export const RegioneOptions = [
	{
		label: "",
		value: undefined
	},
	{
		label: "Abruzzo",
		value: "Abruzzo"
	},
	{
		label: "Basilicata",
		value: "Basilicata"
	},
	{
		label: "Calabria",
		value: "Calabria"
	},
	{
		label: "Campania",
		value: "Campania"
	},
	{
		label: "Emilia-Romagna",
		value: "Emilia-Romagna"
	},
	{
		label: "Friuli-Venezia-Giulia",
		value: "Friuli-Venezia-Giulia"
	},
	{
		label: "Lazio",
		value: "Lazio"
	},
	{
		label: "Liguria",
		value: "Liguria"
	},
	{
		label: "Lombardia",
		value: "Lombardia"
	},
	{
		label: "Marche",
		value: "Marche"
	},
	{
		label: "Molise",
		value: "Molise"
	},
	{
		label: "Piemonte",
		value: "Piemonte"
	},
	{
		label: "Puglia",
		value: "Puglia"
	},
	{
		label: "Sardegna",
		value: "Sardegna"
	},
	{
		label: "Sicilia",
		value: "Sicilia"
	},
	{
		label: "Toscana",
		value: "Toscana"
	},
	{
		label: "Trentino-Alto Adige",
		value: "Trentino-Alto Adige"
	},
	{
		label: "Umbria",
		value: "Umbria"
	},
	{
		label: "Valle d'Aosta",
		value: "Valle d'Aosta"
	},
	{
		label: "Veneto",
		value: "Veneto"
	},
]