import axios from "../../axios.ts";
import { ContactRequestDTO } from "./ContactRequestDTO.tsx";
import { FileDTO } from "./FileDTO.tsx";

export const ContactUsAPI = {
	contactUs: (payload: ContactRequestDTO, encodedFilesBase64: FileDTO[]): Promise<ContactRequestDTO | void> => {
		payload.attachments = encodedFilesBase64;
		payload.isSalePoint = payload.salePoint !== "" && payload.salePoint !== null && payload.salePoint !== undefined
		const requestHeaders = {
			'x-gateway-apikey': window.__RUNTIME_CONFIG__.GATEWAY_KEY
		}
		return axios.post<ContactRequestDTO>(window.__RUNTIME_CONFIG__.REACT_APP_BE_URL, payload, {headers: requestHeaders}).then((response) => {
			return response.data;
		})
	}
};