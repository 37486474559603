import Page from "./Page.tsx";
import React from 'react'
import NotFoundPage from "./pages/NotFoundPage.tsx";
import MainLayout from "./layouts/MainLayout.tsx";
import ContactUsPage from "./pages/ContactUsPage.tsx";
import SuccesSend from "./pages/SuccesSend.tsx";

const pages = [
	{ path: Page[""].path, element: <ContactUsPage /> },
	{ path: Page["/succes_send"].path, element: <SuccesSend /> },
	{ path: Page["/not_found"].path, element: <NotFoundPage /> },
]

export const getRootRoutes = () => [
	{
		path: Page["/"].path,
		element: <MainLayout />,
		children: pages,
	},
];