import React from "react";
import { Outlet } from "react-router-dom";
import "../bundle.scss"

const MainLayout: React.FC = () => {

	return (
		<div className="pageLayout">
			<Outlet />
		</div>
	);
};

export default MainLayout;
